<template>
    <div class="first-view">
        <swiper
            :spaceBetween="60"
            :centeredSlides="true"
            :autoplay="{
                delay: 10000,
                disableOnInteraction: false,
            }"
            :pagination="{
                clickable: true,
            }"
            :navigation="true"
            :modules="modules"
            @autoplayTimeLeft="onAutoplayTimeLeft"
            class="mySwiper"
        >
            <swiper-slide class="slide-1">
                <div class="slide-1-con">
                    <img class="img" src="../../../assets/home/info_bg.png" alt="企业信息化" />
                    <div class="con">
                        <h2 class="title">助力腾飞，共创未来</h2>
                        <div class="desc" @mouseover="auto = false" @mouseleave="auto = true">信息化是企业发展的必经之路，通过利用现代信息技术，对企业的各项业务进行数字化、网络化、智能化，提高企业的办公效率、优化资源配置、增强决策能力、提升服务质量。</div>
                        <ul class="slogan">
                            <li class="item">办公审批</li>
                            <li class="item">财务管理</li>
                            <li class="item">生产安全</li>
                            <li class="item">人力资源</li>
                            <li class="item">市场营销</li>
                        </ul>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-2">
                <div class="slide-2-con">
                    <img class="img" src="../../../assets/home/bg_2.png" alt="电子契约" />
                    <div class="con">
                        <h2 class="title">开启高效、便捷的电子契约新时代</h2>
                        <div class="desc">电子契约采用数字签名和加密技术，确保了契约的安全性和保密性。同时，电子契约的签署和传输速度极快，大大节省了时间成本。</div>
                        <ul class="slogan">
                            <li class="item">法律效力</li>
                            <li class="item">操作便捷</li>
                            <li class="item">高兼容性</li>
                            <li class="item">方便管理</li>
                            <li class="item">快速签约</li>
                        </ul>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-3">
                <div class="slide-3-con">
                    <img class="img" src="../../../assets/home/bg_3.png" alt="无纸化办公" />
                    <div class="con">
                        <h2 class="title">无纸化办公，让工作更轻松</h2>
                        <div class="desc">无纸化办公通过电子设备和数字化技术，实现文件的生成、传输、存储和管理，从而减少或消除纸质文件的使用。无纸化办公不仅可以提高工作效率，还可以降低成本、减少资源浪费，同时保护环境。</div>
                        <ul class="slogan">
                            <li class="item">环保节能</li>
                            <li class="item">高效便捷</li>
                            <li class="item">安全可靠</li>
                            <li class="item">可追溯</li>
                            <li class="item">可验证</li>
                        </ul>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-4">
                <div class="slide-4-con">
                    <img class="img" src="../../../assets/home/bg_4.png" alt="企业形象" />
                    <div class="con">
                        <h2 class="title">引领未来，以质取胜</h2>
                        <div class="desc">注重企业形象策划。让我们帮您塑造独特的品牌形象，提升知名度，为您的事业推波助澜。</div>
                        <ul class="slogan">
                            <li class="item">卓越的企业背景</li>
                            <li class="item">多元化的产品与服务</li>
                            <li class="item">品牌推广与传播</li>
                        </ul>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-5">
                <div class="slide-5-con">
                    <div class="con">
                        <h2 class="title">探索古韵，品味历史</h2>
                        <div class="desc">我们拥有得天独厚的旅游资源，不仅有自然风光，更有丰富的文化遗产。这些文化遗产是我们的宝贵财富，需要我们共同保护、传承和利用。</div>
                        <ul class="slogan">
                            <li class="item">保护文化遗产</li>
                            <li class="item">深度探索历史文化</li>
                            <li class="item">传播国学知识</li>
                            <li class="item">创新旅游体验</li>
                        </ul>
                    </div>
                </div>
            </swiper-slide>
            <template #container-end>
                <div class="autoplay-progress">
                    <svg
                        viewBox="0 0 48 48"
                        ref="progressCircle"
                    >
                        <circle
                            cx="24"
                            cy="24"
                            r="20"
                        ></circle>
                    </svg>
                    <span ref="progressContent"></span>
                </div>
            </template>
        </swiper>
    </div>
</template>

<script>
import { ref } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    setup () {
        const progressCircle = ref(null);
        const progressContent = ref(null);
        const onAutoplayTimeLeft = (s, time, progress) => {
            progressCircle.value.style.setProperty("--progress", 1 - progress);
            progressContent.value.textContent = `${Math.ceil(time / 1000)}s`;
        };
        let auto = ref(true)

        return {
            auto,
            onAutoplayTimeLeft,
            progressCircle,
            progressContent,
            modules: [Autoplay, Pagination, Navigation]
        };
    }
};
</script>

<style lang="less" scoped>
.first-view {
    .slide-1-con,
    .slide-2-con,
    .slide-3-con,
    .slide-4-con,
    .slide-5-con {
        width: 1200px; margin: 0 auto; height: 100%;
        position: relative; z-index: 996;
        .img {
            position: absolute; top: 50%; left: 0; right: 0;
            width: 100%; height: auto;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            opacity: 0.1;
            z-index: 3;
        }
        .con {
            position: absolute; top: 50%; left: 0; right: 0;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            z-index: 5;
            .title {font-size: 4rem; color:#669933;}
            .desc {padding: 0 17%; line-height: 1.75em; color: rgba(#fff, 0.45);}
            .slogan {
                display: flex; list-style: none; padding: 5rem 1rem 0;
                font-size: 1.5rem;
                color: rgba(#fff, 0.75);
                .item {
                    flex: 1;
                }
            }
        }
    }
    .autoplay-progress {
        top: 12vh  !important;
        left: 50%  !important;
        bottom: auto  !important;
        height: 80vh  !important;
        width: 80vh  !important;
        z-index: 2  !important;
        opacity: .05  !important;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
    }
}
</style>
<style lang="less">
    .first-view {
        .swiper-button-prev {left: 5vw!important;}
        .swiper-button-next {right: 5vw!important;}
    }
</style>